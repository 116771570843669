<template>
  <section class="pt-2 pb-6">
    <div class="container grid grid-cols-2 gap-2 md:grid-cols-4">
      <PersonSingle
        v-for="(girl, index) in getGirls"
        :key="index"
        :girl="girl"
      />
    </div>
  </section>
</template>

<script>
import PersonSingle from "@/components/location/PersonGridSingle.vue";

export default {
  name: "PersonGrid",
  computed: {
    getGirls() {
      return this.$store.getters.getGirls;
    },
  },

  components: {
    PersonSingle,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
