import { createStore } from "vuex";
import axios from "axios";
import { getRandomInt } from "@/helpers/random";
// import getRandom from "@/helpers/random";

export default createStore({
  state: {
    roster: [],
    girls: [],
    screen: "Location",
    currentPersonId: 0,
    currentGirlsInLocation: [],
    day: 1,
    cash: 300,
    rep: 5,
    health: 100,
    honour: 0,
    showPopup: false,
    currentLocation: 3,
    locations: [
      {
        id: 0,
        name: "Brownsville",
      },
      {
        id: 1,
        name: "Bed-Sty",
      },
      {
        id: 2,
        name: "Astoria",
      },
      {
        id: 3,
        name: "Williamsburg",
      },
      {
        id: 4,
        name: "Upper East Side",
      },
      {
        id: 5,
        name: "Tribeca",
      },
    ],
  },
  getters: {
    getLocations: (state) => {
      return state.locations;
    },
    currentLocation: (state) => {
      return state.locations.find((x) => x.id === state.currentLocation).name;
    },
    currentGirl: (state) => {
      return state.girls.find((x) => x.id === state.currentPersonId);
    },
    getGirls: (state) => {
      return state.currentGirlsInLocation;
    },
    getRoster: (state) => {
      return state.roster;
    },
    getRandomEventGirlFromRoster: (state) => {
      const shuffled = state.roster.sort(() => 0.5 - Math.random());
      // Get sub-array of first n elements after shuffled
      return shuffled[0];
    },
  },
  mutations: {
    changeScreen(state, n) {
      state.screen = n;
    },
    changeWorkingGirls(state) {
      // random
      const shuffled = state.girls.sort(() => 0.5 - Math.random());
      // Get sub-array of first n elements after shuffled
      state.currentGirlsInLocation = shuffled.slice(0, 4);
    },
    togglePopup(state) {
      state.showPopup = !state.showPopup;
    },
    addGirl(state, girl) {
      state.girls.push(girl);
    },
    setCurrentGirl(state, payload) {
      state.currentPersonId = payload;
    },
    subtractGirlsPriceFromCash(state, payload) {
      console.log(payload);
      state.cash -= payload;
    },
    setLocation(state, payload) {
      state.currentLocation = payload.locationId;
    },
    incrementDay(state) {
      state.day += 1;
    },
    pushGirlToRoster(state, payload) {
      state.roster.push(payload);
    },
    removeGirlFromRoster(state, payload) {
      console.log(payload.girlID);
      state.roster = state.roster.filter((girl) => girl.id !== payload.girlID);
    },
    removeGirlFromGame(state, payload) {
      console.log(payload.girlID);
      state.girls = state.girls.filter((girl) => girl.id !== payload.girlID);
    },
    addEarnings(state) {
      state.roster.forEach((girl) => {
        state.cash += girl.level * 5;
      });
    },
    addRep(state) {
      if (state.rep < 20) {
        state.roster.forEach((girl) => {
          state.rep += girl.level / 50;
        });
      } else if (state.rep < 40) {
        state.roster.forEach((girl) => {
          state.rep += girl.level / 200;
        });
      } else if (state.rep < 60) {
        state.roster.forEach((girl) => {
          state.rep += girl.level / 400;
        });
      } else if (state.rep < 80) {
        state.roster.forEach((girl) => {
          state.rep += girl.level / 800;
        });
      } else {
        state.roster.forEach((girl) => {
          state.rep += girl.level / 1600;
        });
      }
    },
    addEventRep(state, payload) {
      state.rep += payload;
    },
    addCash(state, payload) {
      console.log("addCash Payload:", payload);
      console.log("addCash cash:", state.cash);
      state.cash += payload;
    },
    addHealth(state, payload) {
      state.health += payload;
      if (state.health > 100) {
        state.health = 100;
      }
    },
    addHonour(state, payload) {
      state.honour += payload;
      if (state.honour > 10) {
        state.honour = 10;
      }
      if (state.honour < -10) {
        state.honour = -10;
      }
    },
  },
  actions: {
    changeLocation({ commit }, locationId) {
      commit("setLocation", locationId);
      commit("incrementDay");
      const randomInt = getRandomInt(1, 30);

      // show random event
      if (randomInt < 11) {
        if (this.state.roster.length === 0) {
          const randomEvent = getRandomInt(1, 100);
          if (randomEvent < 34) {
            commit("changeScreen", "FoundCash");
          } else if (randomEvent > 33 && randomEvent < 67) {
            commit("changeScreen", "Donate");
          } else if (randomEvent > 66 && randomEvent < 101) {
            commit("changeScreen", "SponsorEvent");
          }
        } else {
          // random event picker
          const randomEvent = getRandomInt(1, 21);
          if (randomEvent === 21) {
            commit("changeScreen", "GirlKilled");
          } else if (randomEvent < 8) {
            commit("changeScreen", "CustomerNotPaid");
          } else if (randomEvent === 20) {
            commit("changeScreen", "GirlStolen");
          } else if (randomEvent < 12) {
            commit("changeScreen", "FoundCash");
          } else if (randomEvent === 19) {
            commit("changeScreen", "GirlRunsAway");
          } else if (randomEvent < 16) {
            commit("changeScreen", "SponsorEvent");
          } else if (randomEvent < 19) {
            commit("changeScreen", "Donate");
          }
        }
      } else {
        commit("changeScreen", "Location");
      }
      commit("changeWorkingGirls");
      commit("addEarnings");
      commit("addRep");
    },
    loadProfileScreen({ commit }, { girlId, girlsPrice }) {
      commit("setCurrentGirl", girlId);
      commit("subtractGirlsPriceFromCash", girlsPrice);
      commit("changeScreen", "Person");
    },
    loadGame({ commit }) {
      // fetch data from api
      axios
        .get("https://wwarsapi.scutterhut.com/persons/")
        .then((response) => {
          const girls = response.data;
          console.table(girls);
          girls.forEach((girl) => commit("addGirl", girl));
          commit("changeWorkingGirls");
        })
        .catch((error) => console.log(error));
    },
    addGirlToRoster({ commit }, girl) {
      commit("changeScreen", "Location");
      commit("pushGirlToRoster", girl);
      commit("addEventRep", 0.5);
    },
    fireGirl({ commit }, girlID) {
      commit("removeGirlFromRoster", girlID);
    },
    makeDonation({ commit }, { repChange, cashDonated }) {
      commit("subtractGirlsPriceFromCash", cashDonated);
      commit("addEventRep", repChange);
      commit("changeScreen", "Location");
    },
    modifyStatsAndLoadLocation(
      { commit },
      { repChange, cashChange, healthChange, honourChange }
    ) {
      commit("addCash", cashChange);
      commit("addEventRep", repChange);
      commit("addHealth", healthChange);
      commit("addHonour", honourChange);
      commit("changeScreen", "Location");
    },
  },
  modules: {},
});
