<template>
  <div class="home">
    <Title :title="'Help the youth?'" />
    <section class="font-bold">
      <div class="container">
        <p class="text-center p-8">
          A kids summer basketball league is looking for sponsors within the
          community. Will you help?
        </p>
        <div
          class="pt-4 grid grid-cols-2 gap-4 uppercase text-red-600 text-center"
        >
          <button
            @click="makeDonation(sponsor)"
            v-for="sponsor in sponsorList"
            :key="sponsor.cost"
            class="block px-4 py-1 border border-red-600"
          >
            {{ sponsor.sponsorType }} (${{ sponsor.cost }})
          </button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src

import Title from "@/components/Title";

export default {
  name: "SponsorEvent",
  components: {
    Title,
  },
  data() {
    return {
      sponsorList: [
        {
          sponsorType: "Main Sponsor",
          sponsorRep: 3,
          cost: 1000,
        },
        {
          sponsorType: "Minor Sponsor",
          sponsorRep: 1,
          cost: 200,
        },
        {
          sponsorType: "Don't Bother",
          sponsorRep: 0,
          cost: 0,
        },
      ],
    };
  },
  computed: {
    girl() {
      return this.$store.getters.currentGirl;
    },
    rep() {
      return this.$store.state.rep;
    },
    numGirlsInRoster() {
      return this.$store.state.roster.length;
    },
  },
  methods: {
    makeDonation(sponsor) {
      this.$store.dispatch("makeDonation", {
        repChange: sponsor.sponsorRep,
        cashDonated: sponsor.cost,
      });
    },
  },
};
</script>
