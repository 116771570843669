<template>
  <div class="home">
    <Title :title="'Luck of the Irish'" />
    <section class="font-bold">
      <div class="container">
        <p class="text-center p-8">
          You found a wallet on the ground containing ${{ cash }}
        </p>
        <div
          class="pt-4 grid grid-cols-1 gap-2 uppercase text-red-600 text-center"
        >
          <button
            @click="continueGame"
            class="block px-4 py-1 border border-red-600"
          >
            Continue
          </button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { getRandomInt } from "@/helpers/random";
import Title from "@/components/Title";

export default {
  name: "FoundCash",
  components: {
    Title,
  },
  created() {
    this.cash = getRandomInt(0, 1000);
  },
  data() {
    return {
      cash: 0,
    };
  },
  methods: {
    continueGame() {
      this.$store.dispatch("modifyStatsAndLoadLocation", {
        repChange: 0,
        cashChange: this.cash,
        healthChange: 0,
        honourChange: 0,
      });
    },
  },
};
</script>

<style scoped></style>
