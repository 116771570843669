<template>
  <Title :title="getLocation" />
  <PersonGrid />
  <section class="mt-4">
    <div class="container font-bold text-center">
      <a
        @click="showLocationList"
        class="mx-auto w-7/12 block bg-red-600 px-4 py-1 mb-5"
      >
        TRAVEL
      </a>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
import PersonGrid from "@/components/location/PersonGrid.vue";
import Title from "@/components/Title";

export default {
  name: "Location",
  components: {
    PersonGrid,
    Title,
  },
  computed: {
    getLocation() {
      return this.$store.getters.currentLocation;
    },
  },
  methods: {
    showLocationList() {
      this.$store.commit("changeScreen", "LocationList");
    },
  },
};
</script>
