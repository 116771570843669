<template>
  <div class="home">
    <Title :title="'Donate?'" />
    <section class="font-bold">
      <div class="container">
        <p class="text-center p-8">
          A local children's home needs donations or it will close, will you
          help?
        </p>
        <div
          class="pt-4 grid grid-cols-2 gap-4 uppercase text-red-600 text-center"
        >
          <button
            @click="makeDonation(percent)"
            v-for="percent in donateList"
            :key="percent"
            class="block px-4 py-1 border border-red-600"
          >
            Donate {{ percent }}%
          </button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src

import Title from "@/components/Title";

export default {
  name: "Donate",
  components: {
    Title,
  },
  data() {
    return {
      donateList: [0, 10, 25, 50, 75, 100],
    };
  },
  computed: {
    girl() {
      return this.$store.getters.currentGirl;
    },
    rep() {
      return this.$store.state.rep;
    },
    numGirlsInRoster() {
      return this.$store.state.roster.length;
    },
  },
  methods: {
    makeDonation(percent) {
      const cashDonated = this.$store.state.cash * (percent / 100);
      let repChange;
      switch (percent) {
        case 0:
          repChange = -1;
          break;
        case 10:
          repChange = 0;
          break;
        case 25:
          repChange = 0.5;
          break;
        case 50:
          repChange = 1;
          break;
        case 75:
          repChange = 1.5;
          break;
        case 100:
          repChange = 2;
          break;
      }
      this.$store.dispatch("makeDonation", {
        repChange: repChange,
        cashDonated: cashDonated,
      });
    },
  },
};
</script>
