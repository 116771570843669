<template>
  <section
    @click="togglePopup"
    class="
      flex flex-col
      justify-end
      fixed
      top-0
      left-0
      right-0
      bottom-0
      bg-black bg-opacity-60
    "
  >
    <div class="overflow-y-auto mx-h-full bg-black py-4">
      <div class="container">
        <h2 class="text-center font-bold text-base mb-4">ROSTER</h2>
        <div class="grid grid-cols-1 gap-y-3 gap-x-2">
          <RosterItem
            v-for="(girl, index) in rosterGirls"
            :key="index"
            :girl="girl"
          />
        </div>
        <h2
          v-if="rosterGirls.length === 0"
          class="text-center font-bold text-base mb-4"
        >
          No girls on your roster!
        </h2>
      </div>
    </div>
  </section>
</template>

<script>
import RosterItem from "@/components/RosterItem.vue";

export default {
  name: "Popup",
  computed: {
    rosterGirls() {
      return this.$store.getters.getRoster;
    },
  },
  methods: {
    togglePopup() {
      this.$store.commit("togglePopup");
    },
  },

  components: {
    RosterItem,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
