<template>
  <div class="mb-2">
    <figure>
      <img class="w-full" :src="girl.image" alt="" />
    </figure>
    <article>
      <h4 class="py-4 text-sm font-bold text-center capitalize">
        {{ girl.name }}
        <span class="rounded-full p-1 bg-red-600">{{ girl.level }}</span>
      </h4>
      <div
        class="
          flex
          items-center
          justify-between
          gap-1
          uppercase
          text-xs
          font-bold
        "
      >
        <button
          v-if="girl.price > cash"
          @click="this.$toast.error(`Not enough cash!`)"
          class="block bg-red-600 px-4 py-1 uppercase text-center w-full"
        >
          FUCK HER (${{ girl.price }})
        </button>
        <button
          v-else
          @click="showPersonHireScreen(girl.id, girl.price)"
          class="block bg-red-600 px-4 py-1 uppercase text-center w-full"
        >
          FUCK HER (${{ girl.price }})
        </button>
      </div>
    </article>
  </div>
</template>

<script>
export default {
  name: "PersonSingle",
  props: {
    girl: Object,
  },
  computed: {
    cash() {
      return this.$store.state.cash;
    },
  },
  methods: {
    showPersonHireScreen(girlId, girlsPrice) {
      this.$store.dispatch("loadProfileScreen", {
        girlId: girlId,
        girlsPrice: girlsPrice,
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
