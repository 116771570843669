<template>
  <div class="font-bold text-red-600">
    <h5 class="text-sm mb-0.5 capitalize">{{ girl.name }}</h5>
    <div class="flex gap-2">
      <figure>
        <img class="w-20" :src="girl.image" :alt="girl.name" />
      </figure>
      <div class="uppercase">
        <h6 class="text-xs">RATE: ${{ girlsPrice }}</h6>
        <button
          @click="fireGirl(girl.id)"
          class="text-white text-sm uppercase inline-block"
        >
          FIRE HER
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RosterItem",
  props: {
    girl: Object,
  },
  computed: {
    girlsPrice() {
      return this.girl.price;
    },
  },
  methods: {
    fireGirl(id) {
      this.$store.dispatch("fireGirl", {
        girlID: id,
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
