import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import Toaster from "@meforma/vue-toaster";
import "./assets/tailwind.css";
import VueGtag from "vue-gtag";
import plausible from './plugins/plausible';

const plausibleOptions = {
  domain: 'wars.scutterhut.com',  // Update for your site
  hashMode: false,
  trackLocalhost: true,
  apiHost: 'https://plausible.prblm.co'

};

createApp(App)
  .use(store)
  .use(Toaster)
  .use(VueGtag, {
    config: { id: "G-1JNJEM5PXP" },
  })
  .use(plausible, plausibleOptions)
  .mount("#app");
