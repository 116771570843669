<template>
  <div class="home">
    <Title :title="'Customer Service'" />
    <section class="font-bold">
      <div class="container">
        <p class="text-center p-8">
          A rival Pimp has taken {{ girl.name }}. How are you going to deal with
          this muthafucker?
        </p>
        <div
          class="pt-4 grid grid-cols-3 gap-2 uppercase text-red-600 text-center"
        >
          <button
            @click="letHimGo"
            class="block px-4 py-1 border border-red-600"
          >
            Let him have her
          </button>
          <button
            @click="beatHim"
            class="block px-4 py-1 border border-red-600"
          >
            Find her and beat him
          </button>
          <button
            @click="killHim"
            class="block px-4 py-1 border border-red-600"
          >
            Find her and kill him
          </button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src

import Title from "@/components/Title";
import { getRandomInt } from "@/helpers/random";

export default {
  name: "GirlStolen",
  components: {
    Title,
  },
  data() {
    return {};
  },
  computed: {
    girl() {
      return this.$store.getters.getRandomEventGirlFromRoster;
    },
  },
  methods: {
    letHimGo() {
      this.$store.dispatch("modifyStatsAndLoadLocation", {
        repChange: -5,
        cashChange: 0,
        healthChange: 0,
        honourChange: -2,
      });
      this.$store.commit("removeGirlFromRoster", {
        girlID: this.girl.id,
      });
      this.$toast.error(`Your Rep took a big hit you fucking pussy!`);
      this.$toast.error(`${this.girl.name} is no longer in your roster.`);
    },
    beatHim() {
      const randomNum = getRandomInt(1, 10);
      // you get beat up
      if (randomNum === 1) {
        this.$store.dispatch("modifyStatsAndLoadLocation", {
          repChange: -1,
          cashChange: 0,
          healthChange: -60,
          honourChange: 1,
        });
        this.$store.commit("removeGirlFromRoster", {
          girlID: this.girl.id,
        });
        this.$toast.error(`${this.girl.name} is no longer in your roster.`);
        this.$toast.error(
          `He beat the fuck out of you. Your rep took a hit but your honour increased.`
        );
      } else {
        const randomNum = getRandomInt(1, 1000);
        this.$store.dispatch("modifyStatsAndLoadLocation", {
          repChange: 1,
          cashChange: randomNum,
          healthChange: -5,
          honourChange: 2,
        });
        this.$toast.error(
          `You beat that muthafucker down. Your Honour increased.`
        );
        this.$toast.error(`You found ${randomNum} on his body and took it.`);
      }
    },
    killHim() {
      const randomNum = getRandomInt(1, 10);
      if (randomNum < 4) {
        const newCash = this.$store.state.cash * 0.9;
        console.log("newCash:", newCash);
        this.$store.dispatch("modifyStatsAndLoadLocation", {
          repChange: -2,
          cashChange: -Math.abs(newCash),
          healthChange: -100,
          honourChange: 0,
        });

        this.$toast.error(
          `You were shot and killed. You got revived but Hospital bills took most of your cash.`
        );
        this.$store.commit("removeGirlFromRoster", {
          girlID: this.girl.id,
        });
        this.$toast.error(`${this.girl.name} is no longer in your roster.`);
      } else {
        const randomNum = getRandomInt(1, 5000);
        this.$store.dispatch("modifyStatsAndLoadLocation", {
          repChange: 5,
          cashChange: randomNum,
          healthChange: -5,
          honourChange: 2,
        });
        this.$toast.error(
          `You killed that muthafucker. Your Rep increased but your Honour decreased.`
        );
        this.$toast.error(`You found ${randomNum} on his body and took it.`);
      }
    },
  },
};
</script>
