<template>
  <StatusBar />
  <component v-bind:is="screen" />
  <section class="py-4">
    <div class="container font-bold text-center">
      <a @click="togglePopup" class="mx-auto inline-block"> VIEW ROSTER </a>
    </div>
  </section>
  <Popup v-if="showPopup" />
  <p class="text-center mt-6 mb-3">
    Would you like to contribute girls content pack?
    <a class="text-red-500" href="mailto:wwars@protonmail.com">Email us</a>
  </p>
</template>

<script>
import { inject } from 'vue';
import StatusBar from "@/components/StatusBar.vue";
import Title from "@/components/Title.vue";
import Popup from "@/components/Popup.vue";
import Location from "@/components/Location";
import LocationList from "@/components/LocationList";
import Person from "@/components/Person";
import Donate from "@/components/events/Donate";
import CustomerNotPaid from "@/components/events/CustomerNotPaid";
import GirlStolen from "@/components/events/GirlStolen";
import GirlKilled from "@/components/events/GirlKilled";
import FoundCash from "@/components/events/FoundCash";
import GirlRunsAway from "@/components/events/GirlRunsAway";
import SponsorEvent from "@/components/events/SponsorEvent";

export default {
  name: "Home",
  setup() {
    const plausible = inject('plausible');
    return {
      plausible,
    }
  },
  created() {
    this.$store.dispatch("loadGame");
    this.$store.commit("changeWorkingGirls");
  },
  computed: {
    screen() {
      return this.$store.state.screen;
    },
    showPopup() {
      return this.$store.state.showPopup;
    },
  },
  methods: {
    togglePopup() {
      this.$store.commit("togglePopup");
    },
  },

  components: {
    StatusBar,
    Title,
    Popup,
    Location,
    LocationList,
    Person,
    Donate,
    CustomerNotPaid,
    GirlStolen,
    GirlKilled,
    FoundCash,
    GirlRunsAway,
    SponsorEvent,
  },
};
</script>

<style>
body {
  @apply h-screen bg-black text-white overflow-hidden overflow-y-auto;
}
</style>
