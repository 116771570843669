<template>
  <section class="pt-2 pb-2">
    <div class="container">
      <h1 class="text-center font-bold text-2xl">{{ title }}</h1>
    </div>
  </section>
</template>

<script>
export default {
  name: "Title",
  props: {
    title: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
