<template>
  <div class="home">
    <Title :title="girl.name" />
    <section class="font-bold">
      <div class="container">
        <div class="md:grid md:grid-cols-2 md:gap-4">
          <figure>
            <img
              v-if="currentVideo === null"
              class="w-full"
              :src="girl.image"
              alt=""
            />
            <video
              v-if="currentVideo !== null"
              :src="currentVideo"
              autoplay
              muted
              controls
              loop
            ></video>
          </figure>
          <div
            class="
              pt-4
              grid grid-cols-2
              gap-4
              uppercase
              text-red-600 text-center
            "
          >
            <button
              @click="currentVideo = clip.video"
              v-for="(clip, index) in girl.clips"
              :key="index"
              class="block px-4 py-1 border border-red-600"
            >
              {{ clipType(clip.clip_type) }}
            </button>
          </div>
          <div class="pt-4 grid grid-cols-1 gap-4 text-center uppercase">
            <button
              v-if="rep < girl.level"
              class="
                bg-red-600
                block
                px-4
                py-1
                border border-red-600
                disabled:opacity-50
              "
              disabled
            >
              BE HER PIMP (GET YOUR REP UP!)
            </button>
            <button
              v-else-if="!isInRoster"
              class="bg-red-600 block px-4 py-1 border border-red-600"
              @click="addToRoster(girl)"
            >
              BE HER PIMP
            </button>
            <button
              @click="showLocation"
              class="px-4 py-1 border border-red-600"
            >
              LEAVE HER
            </button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src

import Title from "@/components/Title";

export default {
  name: "Person",
  components: {
    Title,
  },
  data() {
    return {
      currentVideo: null,
    };
  },
  computed: {
    isInRoster() {
      return !!this.$store.getters.getRoster.some((e) => e.id === this.girl.id);
    },
    girl() {
      return this.$store.getters.currentGirl;
    },
    rep() {
      return this.$store.state.rep;
    },
    numGirlsInRoster() {
      return this.$store.state.roster.length;
    },
  },
  methods: {
    addToRoster(girl) {
      if (this.numGirlsInRoster < 4) {
        this.$store.dispatch("addGirlToRoster", girl);
      } else {
        this.$toast.error(
          `Not enough space in your roster. Fire a whore to be her Pimp.`
        );
      }
    },
    showLocation() {
      this.$store.commit("changeScreen", "Location");
    },
    clipType(value) {
      switch (value) {
        case 1:
          return "Blow Job";
        case 2:
          return "Fuck";
        case 3:
          return "Anal";
        case 4:
          return "Facial";
        case 5:
          return "Creampie";
        case 6:
          return "Lick her Ass";
        case 7:
          return "Lick your Ass";
        case 8:
          return "Ass to Mouth";
        case 9:
          return "Anal Creampie";
        case 10:
          return "Lick her pussy";
        case 11:
          return "Watch Her";
        case 12:
          return "Tit Fuck";
        case 13:
          return "Cum on her";
        case 14:
          return "Fuck her Face";
        case 15:
          return "Suck her Tits";
        default:
          return value;
      }
    },
  },
};
</script>
