<template>
  <div class="home">
    <Title :title="'She\'s Gone.'" />
    <section class="font-bold">
      <div class="container">
        <p class="text-center p-8">
          You cant get in touch with {{ girl.name }}. Maybe you'll see her again
          one day?
        </p>
        <div
          class="pt-4 grid grid-cols-1 gap-2 uppercase text-red-600 text-center"
        >
          <button
            @click="continueGame"
            class="block px-4 py-1 border border-red-600"
          >
            Continue
          </button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src

import Title from "@/components/Title";

export default {
  name: "GirlRunsAway",
  components: {
    Title,
  },
  mounted() {},
  computed: {
    girl() {
      return this.$store.getters.getRandomEventGirlFromRoster;
    },
  },
  methods: {
    continueGame() {
      this.$toast.error(`${this.girl.name} is no longer in your roster.`);
      this.$store.commit("removeGirlFromGame", {
        girlID: this.girl.id,
      });
      this.$store.commit("changeWorkingGirls");
      this.$store.commit("changeScreen", "Location");
      this.$store.commit("removeGirlFromRoster", {
        girlID: this.girl.id,
      });
    },
  },
};
</script>
