<template>
  <header class="py-2">
    <div
      class="
        container
        flex
        items-center
        gap-6
        justify-between
        text-sm
        font-bold
        uppercase
      "
    >
      <p>DAY: {{ day }}</p>
      <p>CASH: ${{ cash }}</p>
      <p>REP: {{ rep }}</p>
    </div>
  </header>
</template>

<script>
export default {
  name: "StatusBar",
  computed: {
    day() {
      return this.$store.state.day;
    },
    cash() {
      return Math.floor(this.$store.state.cash).toFixed(0);
    },
    rep() {
      return (Math.floor(this.$store.state.rep * 10) / 10).toFixed(1);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
