<template>
  <div class="home">
    <section class="py-14 text-base text-red-600 font-bold">
      <div
        class="
          container
          flex flex-col
          gap-4
          items-center
          justify-center
          text-center
        "
      >
        <button
          @click="changeLocation(location.id)"
          v-for="location in locations"
          :key="location.id"
          class="px-4 py-1 border border-red-600 uppercase"
          :class="{
            'bg-red-600 text-black': location.name === currentLocation,
          }"
        >
          {{ location.name }}
        </button>
        <button
          @click="switchBackToLocationScreen()"
          class="px-4 py-1 border border-red-600 uppercase"
        >
          DON'T TRAVEL
        </button>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "LocationList",
  components: {},
  computed: {
    currentLocation() {
      return this.$store.getters.currentLocation;
    },
    locations() {
      return this.$store.getters.getLocations;
    },
  },
  methods: {
    isCurrentLocation(location) {
      if (location.name === this.currentLocation) {
        return true;
      }
    },
    changeLocation(id) {
      this.$gtag.event("next day", {
        event_category: "clicks",
        event_label: "current day",
        value: this.$store.state.day + 1,
      });
      this.$store.dispatch("changeLocation", {
        locationId: id,
      });
    },
    switchBackToLocationScreen() {
      this.$store.commit("changeScreen", "Location");
    },
  },
};
</script>
